@import "variables";

@font-face {
  font-family: 'smartlaw-icons';
  src:  url('#{$icomoon-font-path}/smartlaw-icons.eot?9v8l0f');
  src:  url('#{$icomoon-font-path}/smartlaw-icons.eot?9v8l0f#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/smartlaw-icons.ttf?9v8l0f') format('truetype'),
    url('#{$icomoon-font-path}/smartlaw-icons.woff?9v8l0f') format('woff'),
    url('#{$icomoon-font-path}/smartlaw-icons.svg?9v8l0f#smartlaw-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'smartlaw-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-contact-form {
  &:before {
    content: $icon-contact-form;
  }
}
.icon-calendar {
  &:before {
    content: $icon-calendar;
  }
}
.icon-noparking {
  &:before {
    content: $icon-noparking;
  }
}
.icon-archive {
  &:before {
    content: $icon-archive;
  }
}
.icon-bell {
  &:before {
    content: $icon-bell;
  }
}
.icon-bell-filled {
  &:before {
    content: $icon-bell-filled;
  }
}
.icon-signature-pen {
  &:before {
    content: $icon-signature-pen;
  }
}
.icon-zoom-in {
  &:before {
    content: $icon-zoom-in;
  }
}
.icon-shelf {
  &:before {
    content: $icon-shelf;
  }
}
.icon-abstracted-document {
  &:before {
    content: $icon-abstracted-document;
  }
}
.icon-apps {
  &:before {
    content: $icon-apps;
  }
}
.icon-burger {
  &:before {
    content: $icon-burger;
  }
}
.icon-dots {
  &:before {
    content: $icon-dots;
  }
}
.icon-account {
  &:before {
    content: $icon-account;
  }
}
.icon-checkmark {
  &:before {
    content: $icon-checkmark;
  }
}
.icon-pen {
  &:before {
    content: $icon-pen;
  }
}
.icon-arrow-circle-down {
  &:before {
    content: $icon-arrow-circle-down;
  }
}
.icon-arrow-circle-left {
  &:before {
    content: $icon-arrow-circle-left;
  }
}
.icon-arrow-circle-right {
  &:before {
    content: $icon-arrow-circle-right;
  }
}
.icon-arrow-circle-up {
  &:before {
    content: $icon-arrow-circle-up;
  }
}
.icon-products {
  &:before {
    content: $icon-products;
  }
}
.icon-documents {
  &:before {
    content: $icon-documents;
  }
}
.icon-folder {
  &:before {
    content: $icon-folder;
  }
}
.icon-arrow-down {
  &:before {
    content: $icon-arrow-down;
  }
}
.icon-arrow-up {
  &:before {
    content: $icon-arrow-up;
  }
}
.icon-arrow-left {
  &:before {
    content: $icon-arrow-left;
  }
}
.icon-arrow-right {
  &:before {
    content: $icon-arrow-right;
  }
}
.icon-download {
  &:before {
    content: $icon-download;
  }
}
.icon-envelope-closed {
  &:before {
    content: $icon-envelope-closed;
  }
}
.icon-envelope-open {
  &:before {
    content: $icon-envelope-open;
  }
}
.icon-information {
  &:before {
    content: $icon-information;
  }
}
.icon-exit {
  &:before {
    content: $icon-exit;
  }
}
.icon-property {
  &:before {
    content: $icon-property;
  }
}
.icon-group {
  &:before {
    content: $icon-group;
  }
}
.icon-person {
  &:before {
    content: $icon-person;
  }
}
.icon-plus {
  &:before {
    content: $icon-plus;
  }
}
.icon-doctoral-cap {
  &:before {
    content: $icon-doctoral-cap;
  }
}
.icon-arrow-curve-left {
  &:before {
    content: $icon-arrow-curve-left;
  }
}
.icon-magnifier {
  &:before {
    content: $icon-magnifier;
  }
}
.icon-telephone {
  &:before {
    content: $icon-telephone;
  }
}
.icon-trash {
  &:before {
    content: $icon-trash;
  }
}
.icon-shopping-cart {
  &:before {
    content: $icon-shopping-cart;
  }
}
.icon-exclamation-mark {
  &:before {
    content: $icon-exclamation-mark;
  }
}
.icon-question-mark {
  &:before {
    content: $icon-question-mark;
  }
}
.icon-light-bulb {
  &:before {
    content: $icon-light-bulb;
  }
}
.icon-cross {
  &:before {
    content: $icon-cross;
  }
}
.icon-circle-cross {
  &:before {
    content: $icon-circle-cross;
  }
}
.icon-label {
  &:before {
    content: $icon-label;
  }
}
.icon-flipchart {
  &:before {
    content: $icon-flipchart;
  }
}
.icon-house {
  &:before {
    content: $icon-house;
  }
}
.icon-magazine {
  &:before {
    content: $icon-magazine;
  }
}
.icon-lock {
  &:before {
    content: $icon-lock;
  }
}
.icon-envelope-closed-filled {
  &:before {
    content: $icon-envelope-closed-filled;
  }
}
.icon-docx {
  &:before {
    content: $icon-docx;
  }
}
.icon-pdf {
  &:before {
    content: $icon-pdf;
  }
}
.icon-rtf {
  &:before {
    content: $icon-rtf;
  }
}
.icon-checklist {
  &:before {
    content: $icon-checklist;
  }
}
.icon-star-empty {
  &:before {
    content: $icon-star-empty;
  }
}

