$icomoon-font-path: "fonts" !default;

$icon-contact-form: "\e938";
$icon-calendar: "\e937";
$icon-noparking: "\e936";
$icon-archive: "\e935";
$icon-bell: "\e933";
$icon-bell-filled: "\e934";
$icon-signature-pen: "\e932";
$icon-zoom-in: "\e931";
$icon-shelf: "\e92c";
$icon-abstracted-document: "\e91a";
$icon-apps: "\e921";
$icon-burger: "\e901";
$icon-dots: "\e90a";
$icon-account: "\e900";
$icon-checkmark: "\e902";
$icon-pen: "\e916";
$icon-arrow-circle-down: "\e903";
$icon-arrow-circle-left: "\e904";
$icon-arrow-circle-right: "\e905";
$icon-arrow-circle-up: "\e906";
$icon-products: "\e909";
$icon-documents: "\e90d";
$icon-folder: "\e910";
$icon-arrow-down: "\e90b";
$icon-arrow-up: "\e920";
$icon-arrow-left: "\e912";
$icon-arrow-right: "\e91c";
$icon-download: "\e90c";
$icon-envelope-closed: "\e90e";
$icon-envelope-open: "\e90f";
$icon-information: "\e911";
$icon-exit: "\e913";
$icon-property: "\e914";
$icon-group: "\e915";
$icon-person: "\e917";
$icon-plus: "\e918";
$icon-doctoral-cap: "\e919";
$icon-arrow-curve-left: "\e91b";
$icon-magnifier: "\e91d";
$icon-telephone: "\e91e";
$icon-trash: "\e91f";
$icon-shopping-cart: "\e922";
$icon-exclamation-mark: "\e923";
$icon-question-mark: "\e924";
$icon-light-bulb: "\e925";
$icon-cross: "\e907";
$icon-circle-cross: "\e908";
$icon-label: "\e929";
$icon-flipchart: "\e926";
$icon-house: "\e927";
$icon-magazine: "\e928";
$icon-lock: "\e92a";
$icon-envelope-closed-filled: "\e92b";
$icon-docx: "\e92d";
$icon-pdf: "\e92e";
$icon-rtf: "\e92f";
$icon-checklist: "\e930";
$icon-star-empty: "\e9d7";

