/*
  Bourbon Settings
*/
$modular-scale-ratio: $major-second !global;

/*
  Neat Settings
*/
//$gutter: 0;
//$column: 1;

$border-box-sizing: true !global;
$max-width: rem(1366) !global;

/*
  Bourbon media breakpoints
*/
$xsmall: new-breakpoint(max-width 480px);
$small: new-breakpoint(max-width 992px);
$medium: new-breakpoint(min-width 993px);
$large: new-breakpoint(min-width 1367px);