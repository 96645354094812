@import "theme";

.pre-headline {
  display: block;
  font-size: $font-general-size-regular;
}

.post-headline {
  font-size: modular-scale(1, $font-general-size-regular);
}

.product-logo {
  height: 100%;
}

.product-info {
  @include media($medium) {
    @include span-columns(9);
  }
};

.top-headline {
  @include media($medium) {
    @include shift(3);
  }
  padding-bottom: 0;
  margin-bottom: 0;
}

.product-price {
  @include media($medium) {
    @include span-columns(3);
    text-align: right;
  }

  table {

    max-width: 140px;

    @include media($medium) {
      float: right;
    }

    tr {
      td {
        color: $color-def-powder-blue-mid;
        padding: 0;

      }

      td:last-child {
        padding-left: 10px;
      }
    }
  }


  h1 {
    font-weight: 600;
    clear: both;

    .post-headline {
      font-weight: 400;
    }
  }
};

.checkout-container {
  text-align: center;
  padding: 0;

  p {
    margin: 0;

    &.break {
      margin-top: 1em;
    }
  }

  button + div {
    clear: both;
    padding-top: 1em;
  }

  section  {
    padding-top: 2em;

    h2 {
      font-size: modular-scale(3, $font-general-size-regular);
      @include media($medium) {
        margin-top: 0;
        @include span-columns(3);
      }
    }

    .center-container {
      form, .form {
        padding: 0;

        &.subsection {
          padding-top: 0.5em;
        }

        .form-row {
          padding-right: 0;
        }
      }

      @include media($medium){
        @include span-columns(9);
      }

      .form .full, form .full, .link-row {
        display: block;
        width: 100%;

        @include media($medium) {
          @include span-columns(7 of 9);
        }
      }

      .error-message-container {
        @include media($medium) {
          @include span-columns(2 of 9);
        }

      }
    }
  }

  button.btn-checkout-long {
    font-size: 0.9rem;
    margin-top: 0.5em;
    height: auto;
    min-height: 3rem;
    line-height: inherit;
    white-space: inherit;
    padding: 0 .875rem;
  }

  button.btn-long {
    margin-top: 0.5em;
    height: auto;
    min-height: 38px;
    line-height: inherit;
    white-space: inherit;
    padding: 0 .875rem;
    text-transform: uppercase;
  }

  a.image-creditcard, a.image-sepa, a.image-paypal  {
    display: inline-block;

    padding: 0.3em 0.5em;
    margin-right: 0.5em;
    border: 1px solid transparent;

    @include media($medium) {
      padding: 0.5em 1.2em;
      margin-right: 0.5em;
    }

    &.active {
      border: 1px solid $color-def-border-grey;
    }

    img {
      height: 20px;
      width: 80px;
    }
  }
}

.checkout-content {
  max-width: 900px;
  margin: 0 auto;
  padding: 0 1em;
  text-align: left;
}

a.secondary-link {
  text-decoration: none;
  color: $color-def-wk-orange;
}

/*
.center-content {
  text-align: left;
  max-width: 500px;
  margin: 0 auto;
}*/


p a, label a {
  margin-right: 0!important;
  padding-right: 0!important;
}

span.original-amount {
  display: block;
  float: right;
  color: $color-def-dull-red;
  text-decoration: line-through;
}

.loader-center {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 12px;
  background-image: url($image-loading-indicator-grey);
  background-repeat: no-repeat;
}

.text-center {
  position: absolute;
  left: 0;
  top: 50%;
  padding: 40px;
  width: 100%;
  text-align: center;
}

.loader {
  width: 20px;
  height: 12px;
  background-image: url($image-loading-indicator-grey);
  background-repeat: no-repeat;
}

.loader.side-button {
  display: inline-block;
  vertical-align: middle;
}

.product-info .form .has-error+.error-message-container {
  @include span-columns(6);
  margin: 0;
}

.emph {
    color: $color-def-wk-orange;
}

.bold {
  font-family: $font-headline-family !important;
}


#zuora_payment {

  &.creditcard {
    height: 350px !important;

    iframe#z_hppm_iframe {
      height: 350px !important;
    }
  }

  &.sepa {
    height: 335px !important;

    iframe#z_hppm_iframe {
      height: 335px !important;
    }

  }
}

#zuora_payment iframe#z_hppm_iframe {
  background-color: white;
  width: 100%;

  @include media($medium) {
    @include span-columns(7 of 9);
  }

}

section .payment {
  padding-bottom: 3em;
}

.status, .error {
  text-align: center;
  clear: both;
  padding-top: 1em;

  p {
    display: block;
    clear: both;
    margin: 0 auto;
    padding-bottom: 1em;
  }
}

.error p {
  color: $color-def-dull-red;
}

.subscriptions {
  @include media($medium) {
    @include span-columns(7);
    @include omega();
  }
}

.invoices  {

  @include media($medium) {
    @include span-columns(7);
    @include omega();
  }

  table {
    width: 100%;

    thead {
      td {
        background-color: $color-def-powder-blue-light;
        color: $color-def-powder-blue-mid;
      }
    }

    tbody {
      td {
        color: $color-def-powder-blue;
      }

      tr:nth-child(odd) td {
        background-color: white;
      }

      tr:nth-child(even) td {
        background-color: #F7F7F8;
      }
    }

    th, td {
      padding: 0 0.875em;
      height: 38px;
    }

    a {
      color: $color-def-wk-orange;
      text-decoration: none;
    }
  }

}

.checkout-container .overlay {
  text-align: center;
  background-color: rgba(255,255,255,0.5);
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;

  .white-box {
    max-width: 800px;
    padding: 2em;
    text-align: left;
    margin: 60px auto;
    background-color: white;
    border: 4px solid $color-def-border-grey;

    section {
      @include clearfix;

      padding-top: 0;

      &.btn-subscription {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      p {
        margin-bottom: 0;
      }
    }

    .mandate-info {
      padding: 2em;
      border: 1px solid $color-def-border-grey;
      margin-bottom: 3em;

      section {
        padding-bottom: 1.5em;
      }
    }


    h2 {
      margin-top: 0;
    }

    p {
      margin-top: 0;
      margin-bottom: 1.5em;
    }

    button {
      @include media($medium) {
        float: right;
        margin-left: 1em;
      }
    }
  }
}

.link-row {
  padding-bottom: 0.5em;

  a {
    float: right;
  }
}

.login-link {
  @include media($medium) {
    float: right;
  }
  display: inline-block;
  padding: 0.75em 0;
}

.form-readonly {
   input, input:focus {
    background-color: #F7F7F8;
    border: none;
  }
}

p.boxed {
  padding: 0.6875em;
  background-color: #F7F7F8;
}

.input-small {
  @include span-columns(2 of 9);

}

.input-large {
  @include span-columns(5 of 9);

}

.subscription-box {
  background-color: #F7F7F8;
  padding: 1em;
  margin-bottom: 0.5em;

  p.name {
    font-size: modular-scale(3, $font-general-size-regular);
  }

  p {
    .label {
      color: $color-def-powder-blue-mid;
    }

    .field {
      float: right;
    }

    padding-bottom: 0.5em;
  }
}

.form-space {
  margin-top: 0.5em;
}

// fix for IE 11 not knowing about input:out-of-range
.form input.has-error, .form:not([novalidate]) input:invalid {
  border: 1px solid #d64539;
  outline: 0
}

div.full p.text {
  margin: -20px 0 10px 30px;
  padding-top: 16px;
  width: 96%;
  line-height: inherit;
}

@media only screen and (max-width: 1000px) {
  div.full p.text {
    margin: -20px 0 10px 30px;
    padding-top: 66px;
    width: 96%;
    line-height: inherit;
  }
}

label.full p.text {
  margin: -22px 0 -30px 30px;
  width: 96%;
  line-height: inherit;
}

.form .form-row p.text:last-child {
  margin-bottom: -28px;
}
