$base-theme: "smartlaw-3";

//$base-resource-url: "/" !default;
$base-resource-url: "https://sa-billing.smartlaw.de/" !default;
$resource-url-postfix: "?v=1456502313696"; // cache break

/*
$icon-font-character-question-open: "\e601";        // pencil
$icon-font-character-question-completed: "\e602";   // check mark
$icon-font-character-topic-open: "\e601";           // pencil
$icon-font-character-topic-completed: "\e602";      // check mark
$icon-font-character-expandable-expanded: "\f077";  // chevron up
$icon-font-character-expandable-collapsed: "\f078"; // chevron down
$icon-font-character-bullet-positive: "\e006"; // check mark
$icon-font-character-download: "\e60a";             // download
$icon-font-character-smart: "\e007";                // SmartLaw
$icon-font-character-information: "\e607";
$icon-font-character-trash-can: "\e608";
$icon-font-character-edit: "\e609";
$icon-font-character-buy: "\e030";
$icon-font-character-clone: "\e008";
$icon-font-character-delete: "\e00a";
$icon-font-character-search: "\e000";
$icon-font-character-text-file: "\e012";
$icon-font-character-add: "\e606";
$icon-font-character-plan: "\e604";
*/

// Form settings
$form-radiobutton-image-checked: $base-resource-url + "images/forms/radio_checked.png" + $resource-url-postfix;
$form-radiobutton-image-unchecked: $base-resource-url + "images/forms/radio_unchecked.png" + $resource-url-postfix;
$form-checkbox-image-checked: $base-resource-url + "images/forms/checkbox_checked.png" + $resource-url-postfix;
$form-checkbox-image-unchecked: $base-resource-url + "images/forms/checkbox_unchecked.png" + $resource-url-postfix;
$form-datepicker-image-default: $base-resource-url + "images/forms/datepicker.png" + $resource-url-postfix;

$image-loading-indicator-grey: $base-resource-url + "images/loading-grey.gif" + $resource-url-postfix;
$image-loading-indicator-white: $base-resource-url + "images/loading-white.gif" + $resource-url-postfix;
$image-loading-indicator-red: $base-resource-url + "images/loading-red.gif" + $resource-url-postfix;
$image-brand-logo: $base-resource-url + "images/tenants/smartlaw/smartlaw-brand.png" + $resource-url-postfix;

$image-creditcard: $base-resource-url + "images/logo_mastercard+visa.svg" + $resource-url-postfix;
$image-sepa: $base-resource-url + "images/logo_lastschrift.svg" + $resource-url-postfix;
$image-paypal: $base-resource-url + "images/logo_paypal.svg" + $resource-url-postfix;

// Theme color definitions
$color-def-dull-red: #d64539;
$color-def-border-grey: #d4d5d7;
$color-def-powder-blue: #3b454e;
$color-def-powder-blue-dark: #28323c;
$color-def-powder-blue-light: #ebebed;
$color-def-powder-blue-mid: #a0a0a4;
$color-def-wk-green: #7bc143;
$color-def-wk-orange: #dc9233;

// Color settings
$color-accent: $color-def-wk-orange;
$color-action: $color-def-wk-green;
$color-action-inverse: #fff;
$color-general: #666666;

$color-text-default: $color-def-powder-blue;
$color-text-highlight: $color-def-powder-blue;

$color-navigation-default: #fff;
$color-navigation-dark: $color-def-powder-blue;
$color-navigation-highlight: $color-def-wk-orange;
$color-mobile-navigation-main: #fff;

$color-border-default: $color-def-border-grey;
$color-border-highlight: $color-def-powder-blue;
$color-border-dark: $color-def-powder-blue-dark;

$color-signal-issue: $color-def-dull-red;
$color-signal-warn: yellow;
$color-signal-ok: #468847;

$color-fill-center: #fff;
$color-fill-periphery: $color-def-powder-blue-light;
$color-fill-navigation: $color-def-powder-blue-dark;
$color-fill-sub-navigation: $color-def-powder-blue;
$color-fill-obligatory: $color-def-powder-blue-dark;

$color-form-placeholder: $color-def-powder-blue-mid;
$color-form-inline-icon: $color-def-powder-blue-mid;

$flash-alert-color: lighten($color-signal-warn, 30%);
$flash-error-color: lighten($color-signal-issue, 30%);
$flash-success-color: lighten($color-signal-ok, 30%);

//Layout settings
$effects-border-radius: 0px;

$layout-header-height: 2.188rem; // 35px

$layout-navigation-height: 4.375rem; // 70px
$layout-mobile-navigation-height: 3.5rem; // 56px
$layout-sub-navigation-height: 2.188rem; // 35px

$layout-footer-height: 3.375rem; // 54px
$layout-mobile-footer-height: 3.5rem; // 56px
$layout-footer-margin: 3.75rem; // 60px

$layout-horizontal-section-padding: 0.9375rem;

// Images
$image-url-product-logo: $base-resource-url + "images/sl_logo_114x24px.png";
$image-url-product-logo-large: $base-resource-url + "images/sl_logo_345x73px.png";
$image-url-corporate-logo: $base-resource-url + "images/wk_logo_114x19px.png";
$image-url-corporate-logo-large: $base-resource-url + "images/wk_logo_174x28px.png";
$image-url-corporate-logo-black-large: $base-resource-url + "images/wk_logo_340x72px.png";
$image-url-corporate-logo-white-large: $base-resource-url + "images/wk_logo_white_340x72px.png";
$image-url-corporate-logo-wheel: $base-resource-url + "images/WK_WR_04_Rev_RGB_Color.png";

//Font settings
$font-general-family: "Bliss Regular", "Gill Sans", Calibri, "Helvetica Neue", Arial, sans-serif;
$font-general-size-regular: 1rem;

$font-headline-family: "Bliss Bold", "Gill Sans", Calibri, "Helvetica Neue", Arial, sans-serif;
$font-headline-size-regular: 1rem;

$font-miscellaneous-family: "Bliss Light", "Gill Sans", Calibri, "Helvetica Neue", Arial, sans-serif;
$font-miscellaneous-size-regular: 1rem;

//$font-path: $base-resource-url + "fonts";
$icomoon-font-path: $base-resource-url + "icons/fonts";

$icon-font-family: "smartlaw-icons";

@import "../bower_components/sl-shared-styles/assets/scss/themes/smartlaw-3/icons/style.scss";
/* todo: add semantic mapping for icon characters here */

//@import "../bower_components/sl-shared-styles/assets/scss/themes/smartlaw-3/fonts/style.scss";

// Shared styles
//@import "../bower_components/sl-shared-styles/assets/scss/shared/main";

@import '../bower_components/normalize-css/normalize';
@import '../bower_components/bourbon/app/assets/stylesheets/bourbon';
@import '../bower_components/neat/app/assets/stylesheets/neat';

@import "../bower_components/sl-shared-styles/assets/scss/shared/settings";
